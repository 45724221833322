import { Location } from '@zupr/types/fo'
import { useEffect, useState } from 'react'

export const normalizeLatLng = ({
    lat,
    lng,
    lon,
}: {
    lat: number
    lng?: number
    lon?: number
}): google.maps.LatLngLiteral => ({
    lat,
    lng: lng || lon,
})

export const distanceBetween = (
    position1: google.maps.LatLngLiteral,
    position2: google.maps.LatLngLiteral
) => {
    if (!position1 || !position2) return '∞'

    if (!!window.google) {
        return window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(position1.lat, position1.lng),
            new window.google.maps.LatLng(position2.lat, position2.lng)
        )
    }

    const toRadians = (val) => (Number(val) * Math.PI) / 180

    const R = 6371e3 // metres
    const φ1 = toRadians(position1.lat)
    const φ2 = toRadians(position2.lat)
    const Δφ = toRadians(position2.lat - position1.lat)
    const Δλ = toRadians(position2.lng - position1.lng)

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
}

export const formatMeters = (meters: number) => {
    return meters < 1000
        ? `${Math.round(meters)}m`
        : `${Math.round(meters / 1000)}km`
}

export const distanceFormat = (position1, position2) => {
    const meters = distanceBetween(position1, position2)
    if (meters === '∞') return meters
    return formatMeters(meters)
}

export const offsetLatLng = (
    map: google.maps.Map,
    position: google.maps.LatLngLiteral,
    offsetX: number,
    offsetY: number,
    zoom?: number
) => {
    if (!map) return position

    const LatLng =
        position instanceof window.google.maps.LatLng
            ? position
            : new window.google.maps.LatLng(position.lat, position.lng)

    if (!map.getProjection()) {
        console.warn('there is no map projection to calculate offset')
        return position
    }

    const point1 = map.getProjection().fromLatLngToPoint(LatLng)
    const point2 = new window.google.maps.Point(
        (typeof offsetX === 'number' ? offsetX : 0) /
            Math.pow(2, zoom || map.getZoom()) || 0,
        (typeof offsetY === 'number' ? offsetY : 0) /
            Math.pow(2, zoom || map.getZoom()) || 0
    )

    const final = map
        .getProjection()
        .fromPointToLatLng(
            new window.google.maps.Point(
                point1.x - point2.x,
                point1.y + point2.y
            )
        )

    return {
        lat: final.lat(),
        lng: final.lng(),
    }
}

export const getDirections = (
    origin: google.maps.LatLngLiteral,
    destination: google.maps.LatLngLiteral
): Promise<google.maps.DirectionsResult> => {
    return new Promise((resolve, reject) => {
        if (!(window.google && window.google.maps))
            reject('google maps not yet loaded')

        const DirectionsService = new window.google.maps.DirectionsService()

        const distance = distanceBetween(origin, destination)

        DirectionsService.route(
            {
                origin,
                destination,
                travelMode:
                    typeof distance === 'number' && distance < 2000
                        ? window.google.maps.TravelMode.WALKING
                        : window.google.maps.TravelMode.DRIVING,
            },
            (directions, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    resolve(directions)
                }
            }
        )
    })
}

interface UseDirections {
    origin: google.maps.LatLngLiteral
    destination: google.maps.LatLngLiteral
}

export const useDirections = ({ origin, destination }: UseDirections) => {
    const [directions, setDirections] = useState<google.maps.DirectionsResult>()

    useEffect(() => {
        if (!origin) return
        if (!destination) return
        getDirections(origin, destination).then(setDirections)
    }, [destination, origin])

    if (!origin || !destination) return null
    return directions
}

export const getBoundsZoomLevel = (bounds, mapDim) => {
    const WORLD_DIM = { height: 256, width: 256 }
    const ZOOM_MAX = 21

    function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180)
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    const lngDiff = ne.lng() - sw.lng()
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

    return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

interface StaticMapOptions {
    zoom?: number
    width?: number
    height?: number
}

export const locationStaticMap = (
    locationId: Location['id'],
    options?: StaticMapOptions
) => {
    const settings = {
        zoom: 14,
        width: 80,
        height: 80,
        ...options,
    }
    return `/api/location/staticmap?locationId=${locationId}&zoom=${settings.zoom}&size=${settings.width}x${settings.height}`
}

export const staticMap = (
    position: google.maps.LatLngLiteral,
    options?: StaticMapOptions
) => {
    const settings = {
        zoom: 14,
        width: 80,
        height: 80,
        ...options,
    }
    return `/api/geolocation/staticmap?lat=${position.lat}&lng=${position.lng}&zoom=${settings.zoom}&size=${settings.width}x${settings.height}`
}

export const inBounds = ({ n, e, s, w }, { lat, lng, lon }) => {
    const latitude = lat
    const longitude = lon || lng
    return !!(
        s <= latitude &&
        latitude <= n &&
        w <= longitude &&
        longitude <= e
    )
}

export const geocoder = async (
    address: string
): Promise<google.maps.GeocoderResult> => {
    return new Promise((resolve, reject) => {
        if (!(window.google && window.google.maps))
            reject('google maps not yet loaded')
        new window.google.maps.Geocoder().geocode(
            {
                address,
            },
            (results, status) => {
                if (
                    status === window.google.maps.GeocoderStatus.OK &&
                    results.length
                ) {
                    resolve(results[0])
                } else {
                    reject(status)
                }
            }
        )
    })
}

// this is an approximation of the bounding box
export const createBoundingBox = (
    location: google.maps.LatLngLiteral,
    distance: number
) => {
    // Calculate the distance for one degree of latitude and longitude
    const latDegKm = 111.32
    const lngDegKm = 111.32 * Math.cos((location.lat * Math.PI) / 180)

    // Calculate the minimum and maximum latitude and longitude
    const minLat = (location.lat - distance / latDegKm).toFixed(4)
    const maxLat = (location.lat + distance / latDegKm).toFixed(4)
    const minLng = (location.lng - distance / lngDegKm).toFixed(4)
    const maxLng = (location.lng + distance / lngDegKm).toFixed(4)

    return `${maxLat}:${minLng}:${minLat}:${maxLng}`
}
