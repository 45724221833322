import { Item, List } from '@zupr/types/generic'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useUrqlRequest, {
    RequestReexecute,
    RequestState,
    UseRequestProps,
    UseRequestResponse,
    useUrl,
} from './request-urql'

export const useRequest = function <State = any>({
    url,
    variables,
    pause,
    method,
}: UseRequestProps): [State, any, any] {
    const dispatch = useDispatch()
    const fullUrl = useUrl({ url, variables })
    const key = `${method}:${fullUrl}`
    const state = useSelector<any>(({ request }) => request[key]) as State

    const handleStore = useCallback(
        ({ url, data }: RequestState<State>) => {
            dispatch({
                type: 'REQUEST_STORE',
                url,
                data,
                method,
            })
        },
        [dispatch, method]
    )

    const [result, reexecute] = useUrqlRequest({
        url: fullUrl,
        pause: pause || !!state, // if we have state no need to fetch
        onStore: handleStore,
        method,
    })

    return useMemo(() => [state, result, reexecute], [reexecute, result, state])
}

interface UseItem {
    url: string
    variables?: Record<string, any>
    pause?: boolean
}

export const useItem = function <Item = any>({
    url,
    variables,
    pause,
}: UseItem): [Item, UseRequestResponse<Item>, RequestReexecute] {
    const dispatch = useDispatch()
    const fullUrl = useUrl({ url, variables })

    const state = useSelector<any>(({ item }) => item[fullUrl]) as Item

    const handleStore = useCallback(
        ({ url, data }: RequestState<Item>) => {
            dispatch({
                type: 'ITEMS_STORE',
                items: {
                    [url]: data,
                },
            })
        },
        [dispatch]
    )

    const [result, reexecute] = useUrqlRequest<Item>({
        url: fullUrl,
        pause: pause || !!state, // if we have state no need to fetch
        onStore: handleStore,
    })

    return useMemo(() => [state, result, reexecute], [state, result, reexecute])
}

interface UseListProps {
    url: string
    variables?: Record<string, any>
    pause?: boolean
    all?: boolean
    simple?: boolean
}

export const useList = function <ListItem = Item>({
    url,
    variables,
    pause,
    all,
    simple, // this request does not fetch full item. It only fetches simplified
}: UseListProps): [
    List<ListItem>,
    UseRequestResponse<List<ListItem>>,
    RequestReexecute
] {
    const dispatch = useDispatch()
    const fullUrl = useUrl({ url, variables })

    // fetch list back from state
    // when all is set it can consist of multiple lists
    const state = useSelector<any>(({ list, item }) => {
        const firstList = list[fullUrl]

        // create new list of urls
        const mergedResults = [...((firstList && firstList.results) || [])]
        let next = firstList && firstList.next

        // add all the next fetched list urls
        while (all && next) {
            const nextList = list[next]

            if (nextList) {
                mergedResults.push(...nextList.results)
            }

            next = nextList && nextList.next
        }

        // find the items back from state
        // if the items are allready exploded return them as is
        const results = mergedResults.map((result) => {
            if (typeof result === 'string' && item[result]) return item[result]
            return result
        })

        return (
            (firstList && {
                ...firstList,
                results,
            }) ||
            null
        )
    }) as List<ListItem>

    // store result in state
    const handleStore: UseRequestProps<List<ListItem>>['onStore'] = useCallback(
        ({ url, data }) => {
            const items = {}

            const results = data.results.map((item: any) => {
                if (simple) return item
                if (item?.url) {
                    items[item.url] = item
                    return item.url
                }
                return item
            })

            dispatch({
                type: 'LIST_STORE',
                url,
                items,
                list: {
                    ...data,
                    results,
                },
            })
        },
        [dispatch, simple]
    )

    // fetch list back api if needed
    const [result, reexecute] = useUrqlRequest<List<ListItem>>({
        url: fullUrl,
        pause: pause || !!state, // if we have state no need to fetch
        all,
        onStore: handleStore,
        state,
    })

    return useMemo(() => [state, result, reexecute], [reexecute, result, state])
}

interface UseRelationProps {
    url: string
    variables?: Record<string, any>
    pause?: boolean
}

interface UseRelationProps {
    url: string
    variables?: Record<string, any>
    pause?: boolean
}

// get an item based on relation between 2 params (eg, product.id && location.id)
export function useRelation<TItem extends Item>({
    url,
    variables,
    pause,
}: UseRelationProps): [
    TItem,
    UseRequestResponse<List<TItem>>,
    RequestReexecute
] {
    const [list, reexecute, getMore] = useList<TItem>({
        url,
        variables: {
            limit: 1,
            ...variables,
        },
        pause,
    })

    // throw error on more results
    if (list?.count > 1) {
        console.error({ url, variables })
        throw Error(
            `Wrong query, never expect more than one result with useRelation. useList otherwise.`
        )
    }

    const item = list?.results?.[0] || null
    return useMemo(() => [item, reexecute, getMore], [item, reexecute, getMore])
}
